import { fabric } from 'fabric'
import GlobalControl from './GlobalControl'

export default class ZoomPanning {
  zoom = 1
  maxZoom = 5
  minZoom = 1
  isPanning = false
  lastPosX = -1
  lastPosY = -1
  canvas
  player
  bgImg
  cursor

  constructor(canvas, player) {
    this.canvas = canvas
    this.player = player
  }

  setBgImg(bgImg) {
    this.bgImg = bgImg
    this.setZoom(1)
  }

  setZoom(zoom) {
    this.zoom = zoom
    const { left, top } = this.canvas.getCenter()
    const pointer = { x: left, y: top }
    this.canvas.zoomToPoint(pointer, this.zoom)
    this.checkViewportBoundary()
  }

  processZoom(e) {
    if (e.e.deltaY > 0 && this.maxZoom > this.zoom) {
      this.zoom += 0.1
      this.zoom = +this.zoom.toFixed(1)
      this.canvas.zoomToPoint(e.pointer, this.zoom)
    } else if (this.minZoom < this.zoom) {
      this.zoom -= 0.1
      this.zoom = +this.zoom.toFixed(1)
      this.canvas.zoomToPoint(e.pointer, this.zoom)
    }
    e.e.preventDefault()
    e.e.stopPropagation()
    this.checkViewportBoundary()
  }

  processAltKey(e, dragLeftButton = false) {
    if ((e.e.altKey || dragLeftButton) && e.e.type === 'mousedown' && e.e.button === 0) {
      GlobalControl.setObjectsSelection(this.canvas, false)
      this.cursor = 'grab'
      GlobalControl.setCursor(this.canvas, this.bgImg, this.cursor)
      this.isPanning = true
      this.lastPosX = e.e.clientX
      this.lastPosY = e.e.clientY
    } else if (this.isPanning && e.e.type === 'mousemove') {
      var vpt = this.canvas.viewportTransform
      vpt[4] += e.e.clientX - this.lastPosX
      vpt[5] += e.e.clientY - this.lastPosY
      this.lastPosX = e.e.clientX
      this.lastPosY = e.e.clientY
      if (this.cursor !== 'grabbing') {
        this.cursor = 'grabbing'
        GlobalControl.setCursor(this.canvas, this.bgImg, this.cursor)
      }
      this.checkViewportBoundary()
    } else if (e.e.type === 'mouseup') {
      if (this.isPanning && !this.player.isPlay) {
        GlobalControl.setObjectsSelection(this.canvas, true)
      }
      this.isPanning = false
      if (e.e.altKey || dragLeftButton) {
        this.cursor = 'grab'
      } else {
        this.cursor = 'default'
      }
      GlobalControl.setCursor(this.canvas, this.bgImg, this.cursor)
    }
  }

  /**
   * @private
   */
  checkViewportBoundary() {
    if (this.bgImg) {
      let vpt = this.canvas.viewportTransform
      let cw = this.canvas.getWidth()
      let ch = this.canvas.getHeight()
      let bgw = +this.bgImg.getScaledWidth().toFixed()
      let bgh = +this.bgImg.getScaledHeight().toFixed()
      let offsetX = ((cw - bgw) / 2) * this.zoom
      let offsetY = ((ch - bgh) / 2) * this.zoom
      if (this.zoom <= 1) {
        vpt[4] = 0
        vpt[5] = 0
      } else {
        // over left
        if (vpt[4] > -offsetX) {
          vpt[4] = -offsetX
        }
        // over up
        if (vpt[5] > -offsetY) {
          vpt[5] = -offsetY
        }
        // over right
        if (vpt[4] < cw - bgw * this.zoom - offsetX) {
          vpt[4] = cw - bgw * this.zoom - offsetX
        }
        // over down
        if (vpt[5] < ch - bgh * this.zoom - offsetY) {
          vpt[5] = ch - bgh * this.zoom - offsetY
        }
      }
    }
  }
}
