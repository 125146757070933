<template>
  <div class="button-fullscreen-icon-container">
    <img src="@/assets/Mobile_FullScreen_Button.svg" width="16px" @click="onClickButton" />
  </div>
</template>

<script>
export default {
  name: 'PlayerFullscreenButton',
  methods: {
    onClickButton() {
      this.$emit('clickToFullscreenPlayer')
    },
  },
}
</script>

<style lang="scss" scoped>
.button-fullscreen-icon-container {
}
</style>
