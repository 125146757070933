import { fabric } from 'fabric'

function setObjectsSelection(canvas, isSelection) {
  canvas.discardActiveObject()
  canvas.set('selection', isSelection)
  canvas.getObjects().forEach((object) => {
    if (!(object instanceof fabric.Image)) {
      if (object.model && !object.model.selectable) {
        object.setCoords()
      } else {
        object.set('selectable', isSelection)
        object.setCoords()
      }
    }
  })
}

function setCursor(canvas, bgImg, cursor) {
  if (canvas) {
    canvas.setCursor(cursor)
    canvas.set('defaultCursor', cursor)
    canvas.set('hoverCursor', cursor)
  }
  if (bgImg) {
    bgImg.set('defaultCursor', cursor)
    bgImg.set('hoverCursor', cursor)
  }
}

export default {
  state: {},
  setObjectsSelection,
  setCursor,
}
