import { fabric } from 'fabric'
import 'fabric-customise-controls'

export class CustomiseControls {
  constructor(canvas) {
    fabric.Canvas.prototype.customiseControls({
      tr: {
        action: (e, target) => {
          target.model.remove(canvas.canvas, canvas.shapes)
          canvas.updateEmitter.emit('update', canvas.toJson())
        },
        cursor: 'pointer',
      },
      function() {
        this.canvas.requestRenderAll()
      },
    })

    fabric.Object.prototype.customiseCornerIcons({
      settings: {
        cornerBackgroundColor: '#fff',
        cornerSize: 5,
      },
      tr: {
        icon: require('../../assets/icon_close-white.svg'),
        settings: {
          cornerSize: 18,
          cornerShape: 'circle',
          cornerBackgroundColor: '#30FFFF',
          cornerPadding: 8,
        },
      },
      function() {
        this.canvas.requestRenderAll()
      },
    })
  }
}
