import { fabric } from 'fabric'
import GlobalControl from './GlobalControl'

export default class Player {
  canvas
  images = []
  imagePath = []
  imageCache = new Map()
  IMAGE_CACHE_SIZE = 150
  isPlay = false
  isPause = false
  playingIndex = 0
  now = 0
  then = 0
  startTime = 0
  elapsed = 0
  FPS_INTERVAL = 1000 / 10
  stopCallback
  animationRequest

  constructor(canvas) {
    this.canvas = canvas
  }

  play(images, startCallback, stopCallback, { speed = 0, updateFrameCallback }) {
    this.imagePath = images
    this.stopCallback = stopCallback
    this.updateFrameCallback = updateFrameCallback
    if (speed) {
      this.FPS_INTERVAL = speed
    }
    if (!this.isPlay && images && images.length) {
      this.isPause = false
      this.isPlay = true
      return Promise.all(images.map((imagePath) => this.loadImage(imagePath))).then((imageObjs) => {
        if (this.isPlay) {
          this.images = imageObjs.filter((image) => {
            if (image) {
              this.fitCanvas(image)
            }
            return !!image
          })
          this.canvas.remove(...this.images)
          this.canvas.add(...this.images)
          const loadedImageSrc = this.images.map((image) => image.getSrc())
          startCallback && startCallback(loadedImageSrc)
          this.startAnimation()
        } else {
          this.stop(true)
        }
      })
    } else if (this.isPlay && this.isPause) {
      // nothing
      return Promise.resolve()
    } else {
      return Promise.resolve(this.stop(true))
    }
  }

  pause(playingIndex) {
    // console.log('Player$pause', playingIndex)
    this.isPlay = false
    this.isPause = true
    if (playingIndex !== undefined) {
      // console.log('Player$pause$playingIndex')
      this.canvas.add(...this.images)
      this.images.forEach((image) => image.set('visible', false))
      this.playingIndex = playingIndex
      this.updateFrameCallback && this.updateFrameCallback(this.playingIndex, false)
      this.images[this.playingIndex] && this.images[this.playingIndex].set('visible', true)
      this.canvas.requestRenderAll()
    }
  }

  stop(isInit, isResize) {
    // console.log('Player$stop')
    if (this.isPlay && this.stopCallback) {
      // console.log('Player$stop$stopCallback')
      this.stopCallback()
    }
    this.isPlay = false
    this.isPause = false
    if (isInit) {
      // console.log('Player$stop$init', this.playingIndex)
      this.playingIndex = 0
      this.updateFrameCallback && this.updateFrameCallback(this.playingIndex, isResize)
      this.images.forEach((image) => image.set('visible', false))
    }
    cancelAnimationFrame(this.animationRequest)
    this.canvas.remove(...this.images)
    GlobalControl.setObjectsSelection(this.canvas, true)
    this.manageCacheSize()
  }

  /**
   * @private
   */
  startAnimation() {
    GlobalControl.setObjectsSelection(this.canvas, false)
    this.then = Date.now()
    this.startTime = this.then
    const performAnimation = () => {
      this.now = Date.now()
      this.elapsed = this.now - this.then
      if (this.elapsed > this.FPS_INTERVAL) {
        this.then = this.now - (this.elapsed % this.FPS_INTERVAL)
        if (this.images[this.playingIndex - 1]) {
          this.images[this.playingIndex - 1].set('visible', false)
          this.canvas.requestRenderAll()
        }
        if (this.images[this.playingIndex]) {
          this.images[this.playingIndex].set('visible', true)
          this.canvas.requestRenderAll()
          // console.log('Player$startAnimation', this.playingIndex)
          this.updateFrameCallback(this.playingIndex, false)
        } else {
          this.stop(true)
        }
        if (this.isPlay && !this.isPause) {
          this.playingIndex++
        } else if (this.isPause) {
          // nothing
        } else {
          this.stop(true)
        }
      }
      if (this.isPlay) {
        this.animationRequest = fabric.util.requestAnimFrame(performAnimation)
      }
    }
    this.animationRequest = fabric.util.requestAnimFrame(performAnimation)
  }

  /**
   * @private
   */
  loadImage(url) {
    if (this.imageCache.has(url)) {
      this.imageCache.get(url).hit++
      this.imageCache.get(url).date = Date.now()
      return Promise.resolve(this.imageCache.get(url).image)
    } else {
      return new Promise((resolve) => {
        fabric.Image.fromURL(url, (image) => {
          if (image.width && image.height) {
            image.set('selectable', false)
            image.set('hoverCursor', 'default')
            image.set('visible', false)
            this.cacheImages(url, image)
            resolve(image)
          } else {
            // load fail
            resolve()
          }
        })
      })
    }
  }

  /**
   * @private
   */
  cacheImages(url, image) {
    this.imageCache.set(url, { hit: 0, date: Date.now(), image })
  }

  /**
   * @private
   */
  manageCacheSize() {
    // restirct cache
    if (this.imageCache.size > this.IMAGE_CACHE_SIZE) {
      const cachedImages = []
      for (const entry of this.imageCache.entries()) {
        cachedImages.push(entry)
      }
      // select hit & date is low
      cachedImages.sort((a, b) => a[1].hit - b[1].hit)
      cachedImages.sort((a, b) => a[1].date - b[1].date)
      const removeItems = cachedImages.slice(0, this.imageCache.size - this.IMAGE_CACHE_SIZE).filter((item) => !this.imagePath.includes(item[0]))
      const removeImages = removeItems.map((item) => item[1].image)
      // console.log('remove caches ', removeImages.length)
      this.canvas.remove(...removeImages)
      removeItems.forEach((item) => {
        this.imageCache.delete(item[0])
        item[1].image.dispose()
      })
    }
  }

  /**
   * @private
   */
  fitCanvas(obj) {
    const canvasW = this.canvas.get('width')
    const canvasH = this.canvas.get('height')
    const ratioW = canvasW / obj.width
    const ratioH = canvasH / obj.height
    if (ratioW < ratioH) {
      obj.scale(ratioW)
    } else {
      obj.scale(ratioH)
    }
    this.canvas.centerObject(obj)
  }
}
