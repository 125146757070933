<template>
  <div @contextmenu="onContextMenu" @mousedown="onMouseDown" style="position: relative; overflow: hidden" :class="{ 'mobile-full': isFullscreen }">
    <Loading :loading="isloading && loading"></Loading>
    <div v-if="!monitor" @click="clickDraw" :class="{ 'icon-draw-container': true, 'icon-draw-container--selected': isDraw || !src }">
      <img src="@/assets/Icon feather-map-pin.svg" class="icon-draw" />
    </div>
    <canvas :id="canvasId"></canvas>
    <ContextMenu v-click-outside="closeContextMenu" :data="contextmenu" @mousedown="onDownloadFrameImage" :width="120">Save Image</ContextMenu>
    <PlayerZoom v-if="src && !mobile" :zoom="zoom" @click="onClickZoom"></PlayerZoom>

    <PlayerFullscreenButton v-if="mobile && !isFullscreen" @clickToFullscreenPlayer="onClickFullScreenPlayer" class="fullscreen-button" />
  </div>
</template>

<script>
import util from '@/util'
import Loading from './Loading.vue'
import Canvas from '@components/canvas/Canvas'
import { mapState } from 'vuex'
import FileSaver from 'file-saver'
import ContextMenu from './ContextMenu'
import PlayerZoom from './PlayerZoom'
import PlayerFullscreenButton from './PlayerFullscreenButtonMobile'
import Util from '@/util'

export default {
  components: { Loading, ContextMenu, PlayerZoom, PlayerFullscreenButton },
  props: {
    liveViewDialog: Boolean,
    canvasId: {
      type: String,
      default: 'imageplayer',
    },
    currentCamImageSrc: {
      type: String,
      default: '',
    },
    currentLog: Object,
    currentLogProps: {
      type: Object,
      default: null,
    },
    hasAfterImages: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    monitor: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    play: {
      type: String,
      default: 'stop',
    },
    // FPS
    speed: {
      type: Number,
      required: false,
      default: 100,
    },
    // when playing, draw boxes
    drawBox: {
      type: Boolean,
      required: false,
      default: false,
    },
    /*
     * true 일경우 모든 인터랙션 가능
     * false 일 경우 모든 인터랙션 불가능
     * object 일 경우 {
     *   selectable: Boolean, // box object 선택 가능 여부
     *   wheel: Boolean, // 마우스 휠로 줌인/아웃 여부
     *   dragLeftButton: Boolean, // 마우스 왼쪽 드래그로 화면 이동 가능
     * }
     */
    active: {
      type: Boolean | Object,
      default: false,
    },
    frame: {
      type: Number,
      default: 0,
    },
    download: {
      type: String | null,
      required: false,
    },
    isloading: {
      type: Boolean,
      default: true,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    isMobileLiveCamImage: {
      // 모바일 화면에서 실시간 캠을 선택하였을때 플레이바를 보여주지 않기 위한 변수입니다.
      type: Boolean,
      default: false,
    },
    /**
     * emit events
     * update: Object
     * margin-left: number
     * stop
     * loaded - loaded images array
     * frame - frame index
     */
  },
  data() {
    return {
      defaultImages: (() => {
        const defaultImage = require('@/assets/playerbar-default-image.jpg')
        return Array(20).fill(defaultImage)
      })(),
      loading: false,
      boxId: 1,
      src: '',
      srcList: [],
      boxList: [],
      isDraw: false,
      box: null,
      canvas: null,
      playNum: 0,
      loaded: false,
      contextmenu: { x: 0, y: 0, display: false },
      currentFrameIndex: 0,
      zoom: 1,
    }
  },
  computed: {
    ...mapState(['user', 'camMap']),
    beforeImagePathsListLength() {
      if (!!this.currentLog && this.currentLog.before_image_paths) {
        return this.currentLog.before_image_paths.length
      } else if (!!this.currentLogProps && this.currentLogProps.before_image_paths) {
        return this.currentLogProps.before_image_paths.length
      } else return 0
    },
  },
  created() {
    this.first = true
    this.created = true
  },
  mounted() {
    this.canvas = new Canvas(document.querySelector(`#${this.canvasId}`), this.active)
    this.canvas.addUpdateHandler(this.updateHandler.bind(this))
    this.canvas.addMarginLeftHandler(this.marginLeftHandler.bind(this))
    this.canvas.addResizeInFullscreenHandler(this.resizeInFullscreenHandler)
    if (this.currentLogProps !== null) this.detectionClipInitialize()
    if (this.currentCamImageSrc !== '' && this.liveViewDialog) this.setNewLiveImageOnCanvas()
  },
  destroyed() {
    this.canvas && this.canvas.dispose()
  },
  watch: {
    currentLogProps(newLog, prevLog) {
      if (newLog && prevLog && newLog.id === prevLog.id && newLog.after_image_paths !== null && prevLog.after_image_paths === null) {
        const addedAfterImg = newLog.after_image_paths.map((path) => util.getImageUrl(path))
        this.srcList.push(...addedAfterImg)
        this.$emit('afterImagePathIsLoaded', this.srcList)
      }
    },

    async currentLog(newLog, prevLog) {
      // this.$log.debug('ImagePlayer$currentlog', 'newLog', newLog, 'prevLog', prevLog)
      if (this.canvas && this.currentLogProps === null) {
        let log = newLog

        if (log) {
          if (log.after_image_paths !== null && prevLog && prevLog.after_image_paths === null && log.log_id === prevLog.log_id) {
            const addedAfterImg = log.after_image_paths.map((path) => util.getImageUrl(path))
            this.srcList.push(...addedAfterImg)
            this.$emit('afterImagePathIsLoaded', this.srcList)
            return
          }
          let changed = this.first || !prevLog
          if (!changed && (log.id || log.log_id)) {
            const PREV_LOG_ID = !!prevLog.id ? prevLog.id : prevLog.log_id
            const NEW_LOG_ID = !!log.id ? log.id : log.log_id

            if (PREV_LOG_ID !== NEW_LOG_ID) {
              changed = true
            }
          }
          if (changed || (newLog !== prevLog && newLog.log_id === prevLog.log_id)) {
            this.first = false
            this.canvas.clear()
            this.canvas.currentLogId = log.id || log.log_id
            this.playNum = 0
            this.zoom = 1
            // get before images
            this.srcList = log.before_image_paths.map((path) => util.getImageUrl(path))
            // push to srcList the detection image
            this.srcList.push(util.getImageUrl(log.detected_image_path))
            // push after images OR default images if after_image_paths is empty (should be inaccessible if empty)
            if (this.hasAfterImages) {
              this.srcList = this.srcList.concat(log.after_image_paths.map((bpath) => util.getImageUrl(bpath)))
            } else if (!this.admin && !this.monitor) {
              this.srcList = this.srcList.concat(this.defaultImages)
            }
            // get frame index at which the detection is found and set to src variable
            this.src = this.srcList[this.beforeImagePathsListLength]
            this.loading = true
            if (!this.canvas.canvas) {
              this.canvas.initialize(this.canvas.canvasElement)
            }
            this.canvas.loadBgImage(this.src).then((result) => {
              this.loading = false
              if (result) {
                this.makeObject(log.objects, log.id || log.log_id)
              }
            })
          }
        } else {
          this.canvas.clear()
          this.playNum = 0
          this.zoom = 1
          this.src = ''
          this.srcList = []
          this.boxList = []
          this.box = null
          this.canvas.loadBgImage(null)
        }
      }
    },
    currentCamImageSrc(val, old) {
      // this.$log.debug('ImagePlayer$currentCamImageSrc', val === old)
      if (val != old) {
        this.setNewLiveImageOnCanvas()
      }
    },
    frame(val) {
      if (this.mobile) {
        this.$log.debug('ImagePlayer$watch$frame', val, this.beforeImagePathsListLength)
        // this.$emit('frame', val, false)
        this.currentFrameIndex = val
        if (this.beforeImagePathsListLength === val) {
          this.canvas.brintToFrontShapes()
          this.canvas.setVisibleShapes(true)
        } else {
          if (this.currentLog && this.currentLog.after_image_paths === null) return
          this.canvas.setVisibleShapes(false)
        }
      } else {
        this.playerPause(val)
      }
    },
    play(val) {
      // this.$log.debug('ImagePlayer$watch$play', val)
      this.closeContextMenu()
      if (this.created) {
        if (val === 'play') {
          this.playerPlay()
        } else if (val === 'pause') {
          this.playerPause()
        } else {
          // this.$log.debug('ImagePlayer$watch$stop', val)
          this.playerStop()
        }
      }
    },
    isFullscreen(val) {
      if (val) {
        this.$log.debug(this.canvas)
      }
    },
  },
  methods: {
    setNewLiveImageOnCanvas() {
      this.loading = true
      this.canvas.clear()
      this.canvas.loadBgImage(this.currentCamImageSrc).then((result) => {
        this.loading = false
      })
    },
    async detectionClipInitialize() {
      if (this.canvas) {
        let log = this.currentLogProps
        this.first = false
        this.canvas.clear()
        this.canvas.currentLogId = log.id || log.log_id
        this.playNum = 0
        this.zoom = 1
        // get before images
        this.srcList = log.before_image_paths.map((path) => util.getImageUrl(path))
        // push to srcList the detection image
        this.srcList.push(util.getImageUrl(log.detected_image_path))
        // push after images OR default images if after_image_paths is empty (should be inaccessible if empty)
        if (this.hasAfterImages) {
          this.srcList = this.srcList.concat(log.after_image_paths.map((bpath) => util.getImageUrl(bpath)))
        } else if (!this.admin && !this.monitor) {
          this.srcList = this.srcList.concat(this.defaultImages)
        }
        // get frame index at which the detection is found and set to src variable
        this.src = this.srcList[this.beforeImagePathsListLength]
        this.loading = true
        if (!this.canvas.canvas) {
          this.canvas.initialize(this.canvas.canvasElement)
        }
        this.canvas.loadBgImage(this.src).then((result) => {
          this.loading = false
          if (result) {
            this.makeObject(log.objects, log.id || log.log_id)
          }
        })
      }
    },
    resizeInFullscreenHandler() {
      this.$emit('automaticPlay')
    },
    makeObject(objects, logId) {
      objects.forEach((object) => {
        object.type = 'Box'
        object.logId = logId
      })
      this.canvas.setShapes(objects)
    },
    updateHandler(json) {
      this.isDraw = false
      this.canvas.setDraw(false)
      this.$emit('update', json)
    },
    marginLeftHandler(marginLeft) {
      this.$emit('margin-left', marginLeft)
    },
    playerPlay() {
      // this.$log.debug('ImagePlayer$playerPlay')
      // this.canvas.stop()
      if (this.playNum === 0 && this.play === 'play') {
        this.loading = true
      }
      let stopped = false
      this.canvas
        .play(
          this.srcList,
          () => {
            this.$log.debug('playerPlay~stopCallback')
            this.$emit('stop')
            stopped = true
          },
          {
            speed: this.speed,
            drawBox: this.beforeImagePathsListLength === this.currentFrameIndex ? true : this.drawBox,
            onLoadedImageSrc: (loadedImageSrc) => {
              // this.$log.debug('ImagePlayer@onLoadedImageSrc')
              stopped = false
              this.$emit('loaded', loadedImageSrc)
            },
            updateFrameCallback: (frameIndex, isResize) => {
              // this.$log.debug('ImagePlayer@updateFrameCallback', frameIndex, isResize)
              this.$emit('frame', frameIndex, isResize)
              this.currentFrameIndex = frameIndex
              if (this.beforeImagePathsListLength === frameIndex || this.drawBox) {
                this.canvas.brintToFrontShapes()
                this.canvas.setVisibleShapes(true)
              } else if (!stopped) {
                this.canvas.setVisibleShapes(false)
              }
            },
          }
        )
        .then(() => {
          this.playNum++
          this.loading = false
        })
    },
    playerPause(playingIndex) {
      this.$log.debug('playerPause', playingIndex)
      this.closeContextMenu()
      this.loading = false
      if (playingIndex === 0 || playingIndex) {
        this.canvas.pause(playingIndex)
      } else {
        this.$emit('pauseForPlayMode')
      }
    },
    playerStop() {
      // this.$log.debug('playerStop')
      this.loading = false
      this.canvas.stop(true)
    },
    onClickZoom() {
      switch (this.zoom) {
        case 1:
          this.zoom = 1.5
          break
        case 1.5:
          this.zoom = 2
          break
        default:
          this.zoom = 1
          break
      }
      this.canvas.setZoom(this.zoom)
    },
    clickDraw() {
      if (this.currentLog) {
        this.isDraw = !this.isDraw
        this.canvas.setDraw(this.isDraw)
      }
    },
    closeContextMenu() {
      this.contextmenu = { x: 0, y: 0, display: false }
    },
    onContextMenu(e) {
      e.preventDefault()
      const secondaryButton = 2
      if (e.button === secondaryButton && this.playNum > 0 && this.download) {
        this.contextmenu = { x: e.x, y: e.y, display: true }
      } else {
        this.closeContextMenu()
      }
    },
    onMouseDown() {
      this.closeContextMenu()
    },
    onDownloadFrameImage(evt) {
      const primaryButton = 0
      let log
      if (this.currentLogProps) log = this.currentLogProps
      else log = this.currentLog
      if (evt.button === primaryButton) {
        const src = this.srcList[this.currentFrameIndex] + '?nocache=' + Date.now()
        const srcSplitDot = this.srcList[this.currentFrameIndex].split('.')
        const ext = srcSplitDot[srcSplitDot.length - 1]
        const cam = this.camMap[log.camera_id]
        let date = Util.getOffsetDate(log.created_at, log.offset)
        date = date.replace(/:/g, '-')
        const newFileName = `${date}_${cam.name}_${this.currentFrameIndex}.${ext}`
        // console.log(src, newFileName)
        FileSaver.saveAs(src, newFileName)
      }
    },
    // When the user clicks on the fullscreen button, emit to parent
    onClickFullScreenPlayer() {
      if (this.mobile) {
        // this.$log.debug('ImagePlayer$onClickFullScreenPlayer')
        this.$emit('onClickFullScreenPlayer')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-draw-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 0px;
  width: 60px;
  height: 60px;
  background-color: #ff4e00;
  box-shadow: 0px 4px 10px 0px #0000000f;
  border-radius: 10px 0 0 10px;
  z-index: 1;
  cursor: pointer;
}

.icon-draw-container--selected {
  opacity: 0.5;
}

.icon-draw-container:active {
  opacity: 0.5;
}

.icon-draw {
  width: 22px;
  height: 30px;
}

.fullscreen-button {
  position: absolute;
  right: 10px;
  bottom: 4px;
}
.downsize-button {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 30px;
  width: 30px;
  background-color: red;
  transform: translate(-50%, -50%);
}
.mobile-full {
  height: 100%;
}
</style>
