import { fabric } from 'fabric'
import ZoomPanning from './ZoomPanning'
import Player from './Player.js'
import Box from './Box'
import { EventEmitter } from 'events'
import { transFromDataToBox, transFromBoxToData } from './util'
import { CustomiseControls } from './CustomiseControls'
import GlobalControl from './GlobalControl'

export default class Canvas {
  canvas
  canvasElement
  scale = 1
  bgImgPath
  bgImg
  zoomPanning
  player
  resize$
  shapes = []
  backupJson
  customiseControls
  downX
  downY
  isDraw = false
  updateEmitter = new EventEmitter()
  active = true
  dragLeftButton = false
  cursor
  intervalCheckCanvasSize
  resizeTimeout
  currentLogId = 0

  constructor(canvasElement, active = true) {
    this.canvasElement = canvasElement
    this.active = active
    this.initialize(canvasElement)
  }

  /**
   * @private
   */
  initialize(canvasElement) {
    this.canvas = new fabric.Canvas(canvasElement, {
      width: canvasElement.parentNode.clientWidth,
      height: canvasElement.parentNode.clientHeight,
      hasRotatingPoint: false,
    })
    this.player = new Player(this.canvas)
    this.zoomPanning = new ZoomPanning(this.canvas, this.player)
    this.customiseControls = new CustomiseControls(this)
    if (this.active) {
      if (this.active !== true && this.active.selectable) {
        fabric.Object.prototype.hasControls = true
        fabric.Object.prototype.evented = true
      }
      if (this.active === true || (this.active !== true && this.active.wheel)) {
        this.canvas.on('mouse:wheel', this.onMouseWheel.bind(this))
      }
      if (this.active !== true && this.active.dragLeftButton) {
        this.dragLeftButton = true
      }
      this.canvas.on('mouse:down:before', this.onMouseDownBefore.bind(this))
      this.canvas.on('mouse:down', this.onMouseDown.bind(this))
      this.canvas.on('mouse:move', this.onMouseMove.bind(this))
      this.canvas.on('mouse:up', this.onMouseUp.bind(this))
    } else {
      fabric.Object.prototype.hasControls = false
      fabric.Object.prototype.evented = false
      this.canvas.selectionBorderColor = 'rgba(255, 255, 255, 0)'
      this.canvas.selectionColor = 'rgba(255, 255, 255, 0)'
    }
    this.registerOnResize()
    this.updateEmitter.emit('resizeInFullscreen')
    if (this.active) {
      fabric.Object.prototype.evented = true
      fabric.Object.prototype.hasControls = true
      // this.canvas.requestRenderAll()
    }
  }

  /**
   * @private
   */
  onMouseWheel(e) {
    this.zoomPanning.processZoom(e)
    this.canvas.requestRenderAll()
  }

  /**
   * @private
   */
  onMouseDownBefore(e) {
    this.zoomPanning.processAltKey(e, this.dragLeftButton)
  }

  /**
   * @private
   */
  onMouseDown(e) {
    this.downX = e.absolutePointer.x
    this.downY = e.absolutePointer.y
  }

  /**
   * @private
   */
  onMouseMove(e) {
    this.zoomPanning.processAltKey(e, this.dragLeftButton)
    this.canvas.requestRenderAll()
  }

  /**
   * @private
   */
  onMouseUp(e) {
    if (!this.zoomPanning.isPanning) {
      // edit
      if (!this.isDraw && e.transform && e.transform.action) {
        if (e.transform.action.startsWith('scale') || e.transform.action.startsWith('drag')) {
          e.target.model.shapedUpdate()
          this.updateEmitter.emit('update', this.toJson())
        }
      }

      // draw
      const width = Math.abs(e.absolutePointer.x - this.downX)
      const height = Math.abs(e.absolutePointer.y - this.downY)

      if (this.isDraw && width && height) {
        if (this.canvas.getActiveObject()) {
          this.canvas.discardActiveObject()
        }
        const box = new Box({
          x: this.downX < e.absolutePointer.x ? this.downX : e.absolutePointer.x,
          y: this.downY < e.absolutePointer.y ? this.downY : e.absolutePointer.y,
          width,
          height,
          selectable: this.active === true || this.active.selectable,
        })
        this.shapes.push(box)
        box.draw(this.canvas)
        this.canvas.discardActiveObject()
        this.updateEmitter.emit('update', this.toJson())
      }
    }

    if (!!this.canvas.getActiveObject() && this.canvas.getActiveObject().type === 'activeSelection') {
      this.canvas.discardActiveObject()
    }
    this.zoomPanning.processAltKey(e)
    this.canvas.requestRenderAll()
  }

  /**
   * @private
   */
  registerOnResize() {
    // console.log('Canvas$registerOnResize')
    if (!this.resizeHandler) {
      this.resizeHandler = () => {
        clearTimeout(this.resizeTimeout)
        // console.log('Canvas$registerOnResize$dispose')
        this.dispose(true)
        this.resizeTimeout = setTimeout(() => {
          this.reload()
        }, 500)
      }
      window.addEventListener('resize', this.resizeHandler)
    }
  }

  /**
   * @private
   */
  startCheckWindowSize() {
    clearTimeout(this.intervalCheckCanvasSize)
    this.intervalCheckCanvasSize = setTimeout(() => {
      if (!this.checkWindowSize()) {
        this.startCheckWindowSize()
      }
    }, 500)
    this.checkWindowSize()
  }

  /**
   * @private
   */
  checkWindowSize() {
    if (
      this.canvas &&
      (this.canvasElement.parentNode.parentNode.clientWidth !== this.canvasElement.clientWidth ||
        this.canvasElement.parentNode.parentNode.clientHeight !== this.canvasElement.clientHeight)
    ) {
      this.reload()
      return true
    }
    return false
  }

  reload() {
    // console.log('Canvas$reload')
    this.dispose()
    this.initialize(this.canvasElement)
    if (this.bgImgPath) {
      this.loadBgImage(this.bgImgPath).then(() => {
        this.setShapes(this.backupJson)
      })
    }
  }

  loadBgImage(url) {
    this.bgImgPath = url
    if (url) {
      return new Promise((resolve) => {
        fabric.Image.fromURL(url, (image) => {
          if (this.canvas && image.width && image.height && url == this.bgImgPath) {
            image.set('selectable', false)
            image.set('hoverCursor', 'default')
            this.fitCanvas(image)
            this.canvas.add(image)
            this.zoomPanning.setBgImg(image)
            this.bgImg = image
            this.canvas.renderAll()
            resolve(image)
            this.startCheckWindowSize()
          } else {
            // load fail
            resolve(null)
          }
        })
      })
    } else {
      return Promise.resolve(null)
    }
  }

  setZoom(zoom) {
    this.zoomPanning.setZoom(zoom)
    this.canvas.requestRenderAll()
  }

  /**
   * @private
   */
  fitCanvas(obj) {
    const canvasW = this.canvas.get('width')
    const canvasH = this.canvas.get('height')
    const ratioW = canvasW / obj.width
    const ratioH = canvasH / obj.height
    if (ratioW < ratioH) {
      obj.scale(ratioW)
    } else {
      obj.scale(ratioH)
    }
    this.canvas.centerObject(obj)
    this.updateEmitter.emit('margin-left', obj.left)
    this.scale = obj.get('scaleX')
  }

  play(images, stopCallback, options) {
    if (!options.drawBox) {
      this.setVisibleShapes(false)
    }
    return new Promise((resolve) => {
      this.player
        .play(
          images,
          (loadedImageSrc) => {
            if (options.drawBox) {
              this.brintToFrontShapes()
            }
            if (options.onLoadedImageSrc && typeof options.onLoadedImageSrc === 'function') {
              options.onLoadedImageSrc(loadedImageSrc)
            }
          },
          () => {
            this.brintToFrontShapes()
            this.setVisibleShapes(true)
            stopCallback && stopCallback()
          },
          options
        )
        .then(() => {
          resolve()
        })
    })
  }

  pause(playingIndex) {
    this.player.pause(playingIndex)
  }

  stop(isInit, isResize) {
    // console.log('Canvas$stop', isInit)
    this.player.stop(isInit, isResize)
  }

  clear(isResize) {
    if (this.canvas) {
      this.backupJson = this.toJson()
      this.shapes = []
      // console.log('Canvas$clear')
      this.stop(true, isResize)
      this.canvas.clear()
    }
  }

  dispose(isResize) {
    if (this.canvas) {
      clearTimeout(this.intervalCheckCanvasSize)
      // console.log('Canvas$dispose')
      this.clear(isResize)
      this.canvas.off()
      this.canvas.dispose()
      this.canvas = null
    }
  }

  setShapes(objects) {
    objects.forEach((object) => {
      const obj = transFromDataToBox(object, this.canvasElement, this.bgImg)
      const box = new Box({ ...obj, selectable: this.active === true || this.active.selectable })
      if (obj.logId === this.currentLogId) {
        this.shapes.push(box)
      }
      box.draw(this.canvas)
    })
    this.backupJson = this.toJson()
  }

  setVisibleShapes(isVisible) {
    this.shapes.forEach((shape) => {
      shape.setVisible(isVisible, this.shapes)
    })
  }

  brintToFrontShapes() {
    this.shapes.forEach((shape) => {
      shape.fabricObject.bringToFront()
    })
  }

  toJson() {
    return this.shapes.map((shape) => {
      return transFromBoxToData(shape, this.canvasElement, this.bgImg, shape.logId)
    })
  }

  setDraw(isDraw) {
    this.isDraw = isDraw
    if (isDraw) {
      fabric.Object.prototype.evented = false
      GlobalControl.setCursor(this.canvas, this.bgImg, 'crosshair')
      this.disabledActiveObject()
    } else {
      fabric.Object.prototype.evented = true
      GlobalControl.setCursor(this.canvas, this.bgImg, 'default')
    }
  }

  addUpdateHandler(handler) {
    this.updateEmitter.on('update', handler)
  }

  addResizeInFullscreenHandler(handler) {
    this.updateEmitter.on('resizeInFullscreen', handler)
  }

  addMarginLeftHandler(handler) {
    this.updateEmitter.on('margin-left', handler)
  }

  disabledActiveObject() {
    if (this.canvas) {
      this.canvas.discardActiveObject()
      this.canvas.requestRenderAll()
    }
  }
}
